<template>
  <v-container fluid style="width: 80%">
    <div>
      <v-row>
        <v-col cols="12">
          <v-data-table
            dense
            :headers="headers"
            :items="filteredCasefiles"
            :sort-by="['days']"
            :sort-desc="[true]"
            :footer-props="{
              showFirstLastPage: true,
              'items-per-page-options': [10, 20, 30, 40, 50, 100],
            }"
            :items-per-page="30"
            :search="search"
            class="elevation-1"
            :loading="loadTable"
            loading-text="Working on it ......"
            mobile-breakpoint="1100"
            @click:row="(item) => viewItem(item)"
            style="cursor: pointer"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="addDialog" max-width="600px" persistent>
                  <template v-slot:activator="{ on: dialog, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          fab
                          small
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...dialog }"
                          :disabled="addCasefileDisabled"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>ADD CASEFILE</span>
                    </v-tooltip>
                  </template>

                  <v-card tile>
                    <v-card-title class="justify-center text-h5 grey lighten-2">
                      <span class="text-h5">ADD NEW CASE</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-card width="100%">
                            <v-card-title>Department</v-card-title>
                            <v-col cols="12">
                              <v-select
                                v-model="newItem.refType"
                                :items="refType"
                                item-text="name"
                                item-value="id"
                                :menu-props="{ maxHeight: '400' }"
                                hint="Select Department"
                                persistent-hint
                                label="Department"
                              >
                                <template #label>
                                  <span class="red--text"
                                    ><strong>* </strong></span
                                  >Department
                                </template>
                              </v-select>
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                v-model="newItem.subRefType"
                                :items="filtersubRefType(newItem.refType)"
                                item-text="subCode"
                                item-value="id"
                                no-data-text="There is no file-Classification option for selected department above"
                                :menu-props="{ maxHeight: '400' }"
                                label="File-Classification"
                                hint="Select File-Classification"
                                persistent-hint
                              ></v-select>
                            </v-col>
                          </v-card>
                          <v-col cols="12">
                            <v-select
                              v-model="newItem.branch"
                              :items="branch"
                              item-text="name"
                              item-value="id"
                              :menu-props="{ maxHeight: '400' }"
                              hint="Set the casefile branch"
                              persistent-hint
                              label="Branch"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              v-model="newItem.insurer"
                              :items="insurer"
                              item-text="name"
                              item-value="id"
                              :menu-props="{ maxHeight: '400' }"
                              hint="Set the casefile insurer"
                              persistent-hint
                            >
                              <template #label>
                                <span class="red--text"
                                  ><strong>* </strong></span
                                >Insurer
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="newItem.handler"
                              :items="filterHandler(newItem.insurer)"
                              item-text="name"
                              item-value="id"
                              no-data-text="There is no handler option for selected insurer above"
                              :menu-props="{ maxHeight: '400' }"
                              label="Claim Handler"
                              hint="Select Handler"
                              persistent-hint
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="newItem.claimNo"
                              item-text="Insurer's Reference"
                              label="Insurers Reference Number"
                              :rules="[
                                (v) => !!v || 'Insurers reference is required',
                              ]"
                            >
                              <template #label>
                                <span class="red--text"
                                  ><strong>* </strong></span
                                >Insurers Reference
                              </template></v-text-field
                            >
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <template>
                              <div>
                                <v-menu
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  max-width="290"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="ComputedDateOfAssignAdd"
                                      persistent-hint
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      @click:clear="date = null"
                                    >
                                      <template #label>
                                        <span class="red--text"
                                          ><strong>* </strong></span
                                        >Date Of Assignment
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="newItem.dateOfAssign"
                                    @change="menu = false"
                                  ></v-date-picker>
                                </v-menu>
                              </div>
                            </template>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="newItem.vehicleNo"
                              label="Vehicle Number"
                              hint="Vehicle Number"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <template>
                              <div>
                                <v-menu
                                  v-model="menu1"
                                  :close-on-content-click="false"
                                  max-width="290"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="ComputedDateOfLossAdd"
                                      persistent-hint
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      @click:clear="date = null"
                                    >
                                      <template #label>
                                        <span class="red--text"
                                          ><strong>* </strong></span
                                        >Date Of Loss
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="newItem.dateOfLoss"
                                    @change="menu1 = false"
                                  ></v-date-picker>
                                </v-menu>
                              </div>
                            </template>
                          </v-col>
                          <v-card width="100%">
                            <v-card-title>Place Of Loss</v-card-title>
                            <v-col cols="12">
                              <v-select
                                v-model="newItem.stateOfLoss"
                                :items="state"
                                item-text="name"
                                item-value="id"
                                :menu-props="{ maxHeight: '400' }"
                                hint="Select State"
                                persistent-hint
                                label="State"
                              >
                              </v-select>
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                v-model="newItem.placeOfLoss"
                                :items="filterRegions(newItem.stateOfLoss)"
                                item-text="name"
                                item-value="name"
                                no-data-text="There is no district option for selected state above"
                                :menu-props="{ maxHeight: '400' }"
                                label="Area Of Loss"
                                hint="Select District"
                                persistent-hint
                              ></v-select>
                            </v-col>
                          </v-card>

                          <v-col cols="12">
                            <v-text-field
                              v-model="newItem.insuredName"
                              label="Insured Name"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              v-model="newItem.insComment"
                              label="Insurers Comment"
                            >
                              <template #label>
                                <span class="red--text"
                                  ><strong>* </strong></span
                                >Insurers Comment
                              </template>
                            </v-textarea>
                          </v-col>

                          <v-col cols="12" sm="12" md="12">
                            <v-select
                              v-model="newItem.fileStatus"
                              :items="stages"
                              item-text="name"
                              item-value="stageCode"
                              :menu-props="{ maxHeight: '400' }"
                              label="FILE STATUS"
                              hint="Set File Status - Will be removed on Production version"
                              persistent-hint
                            ></v-select>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-spacer></v-spacer>
                            <v-btn
                              outlined
                              small
                              tile
                              @click="closeAddDialog"
                              class="mt-3 mr-2"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              :disabled="isAddFileButtonDisabled"
                              class="success mt-3"
                              small
                              tile
                              @click="addCasefile"
                              >Submit</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="editDialog" max-width="600px" persistent>
                  <v-card tile>
                    <v-card-title class="justify-center text-h5 grey lighten-2">
                      <span class="text-h5">EDIT CASEFILE</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-card width="100%">
                            <v-card-title>Department</v-card-title>
                            <v-col cols="12">
                              <v-select
                                v-model="editItem.refType"
                                :items="refType"
                                item-text="name"
                                item-value="id"
                                :menu-props="{ maxHeight: '400' }"
                                hint="Select Department"
                                persistent-hint
                                label="Department"
                              >
                                <template #label>
                                  <span class="red--text"
                                    ><strong>* </strong></span
                                  >Department
                                </template>
                              </v-select>
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                v-model="editItem.subRefType"
                                :items="filtersubRefType(editItem.refType)"
                                item-text="subCode"
                                item-value="id"
                                no-data-text="There is no file-Classification option for selected department above"
                                :menu-props="{ maxHeight: '400' }"
                                label="File-Classification"
                                hint="Select File-Classification"
                                persistent-hint
                              ></v-select>
                            </v-col>
                          </v-card>
                          <v-col cols="12">
                            <v-select
                              v-model="editItem.branch"
                              :items="branch"
                              item-text="name"
                              item-value="id"
                              :menu-props="{ maxHeight: '400' }"
                              hint="Set the casefile branch"
                              persistent-hint
                              label="Branch"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              v-model="editItem.insurer"
                              :items="insurer"
                              item-text="name"
                              item-value="id"
                              :menu-props="{ maxHeight: '400' }"
                              hint="Set the casefile insurer"
                              persistent-hint
                            >
                              <template #label>
                                <span class="red--text"
                                  ><strong>* </strong></span
                                >Insurer
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="editItem.handler"
                              :items="filterHandler(editItem.insurer)"
                              item-text="name"
                              item-value="id"
                              no-data-text="There is no handler option for selected insurer above"
                              :menu-props="{ maxHeight: '400' }"
                              label="Claim Handler"
                              hint="Select Handler"
                              persistent-hint
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editItem.claimNo"
                              item-text="Insurer's Reference"
                              label="Insurers Reference Number"
                              :rules="[
                                (v) => !!v || 'Insurers reference is required',
                              ]"
                            >
                              <template #label>
                                <span class="red--text"
                                  ><strong>* </strong></span
                                >Insurers Reference
                              </template></v-text-field
                            >
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <template>
                              <div>
                                <v-menu
                                  v-model="menu2"
                                  :close-on-content-click="false"
                                  max-width="290"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="ComputedDateOfAssignEdit"
                                      persistent-hint
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      @click:clear="date = null"
                                    >
                                      <template #label>
                                        <span class="red--text"
                                          ><strong>* </strong></span
                                        >Date Of Assignment
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="editItem.dateOfAssign"
                                    @change="menu2 = false"
                                  ></v-date-picker>
                                </v-menu>
                              </div>
                            </template>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editItem.vehicleNo"
                              label="Vehicle Number"
                              hint="Vehicle Number"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <template>
                              <div>
                                <v-menu
                                  v-model="menu3"
                                  :close-on-content-click="false"
                                  max-width="290"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="ComputedDateOfLossEdit"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      @click:clear="date = null"
                                    >
                                      <template #label>
                                        <span class="red--text"
                                          ><strong>* </strong></span
                                        >Date Of Loss
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="editItem.dateOfLoss"
                                    @change="menu3 = false"
                                  ></v-date-picker>
                                </v-menu>
                              </div>
                            </template>
                          </v-col>
                          <v-card width="100%">
                            <v-card-title>Place Of Loss</v-card-title>
                            <v-col cols="12">
                              <v-select
                                v-model="editItem.stateOfLoss"
                                :items="state"
                                item-text="name"
                                item-value="id"
                                :menu-props="{ maxHeight: '400' }"
                                hint="Select State"
                                persistent-hint
                                label="State"
                              >
                              </v-select>
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                v-model="editItem.placeOfLoss"
                                :items="filterRegions(editItem.stateOfLoss)"
                                item-text="name"
                                item-value="name"
                                no-data-text="There is no district option for selected state above"
                                :menu-props="{ maxHeight: '400' }"
                                label="Area Of Loss"
                                hint="Select District"
                                persistent-hint
                              ></v-select>
                            </v-col>
                          </v-card>

                          <v-col cols="12">
                            <v-text-field
                              v-model="editItem.insuredName"
                              label="Insured Name"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              v-model="editItem.insComment"
                              label="Insurers Comment"
                            >
                              <template #label>
                                <span class="red--text"
                                  ><strong>* </strong></span
                                >Insurers Comment
                              </template>
                            </v-textarea>
                          </v-col>

                          <v-col cols="12" sm="12" md="12">
                            <v-select
                              v-model="editItem.fileStatus"
                              :items="stages"
                              item-text="name"
                              item-value="stageCode"
                              :menu-props="{ maxHeight: '400' }"
                              label="FILE STATUS"
                              hint="Set File Status - Will be removed on Production version"
                              persistent-hint
                            ></v-select>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-spacer></v-spacer>
                            <v-btn
                              outlined
                              small
                              tile
                              @click="closeEditDialog"
                              class="mt-3 mr-2"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              :disabled="isEditFileButtonDisabled"
                              small
                              tile
                              class="success mt-3"
                              @click="editCasefile"
                              >Submit</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="justify-center text-h5 grey lighten-2"
                      >Confirm delete?
                      <!-- {{ editItem.id }}? -->
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined small tile @click="closeDelete">No</v-btn>
                      <v-btn small tile class="error" @click="deleteItemConfirm"
                        >Yes</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- <v-dialog v-model="dialogAcceptCase" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >ACCEPT ASSIGNMENT?</v-card-title
                    >
                    <v-card-text>
                      CASEFILE NUMBER: AA<span
                        v-for="(dept, index) in filterDept(editItem.refType)"
                        :key="index"
                        >/{{ dept.name
                        }}<span
                          v-for="(subDept, index) in filterSubDept(
                            editItem.subRefType
                          )"
                          :key="index"
                          >/{{ subDept.subCode }}</span
                        ></span
                      >/{{ editItem.id }}/{{ yearOnly(editItem.createdAt)
                      }}<span
                        v-for="branch in filterBranches(editItem.branch)"
                        :key="branch.id"
                        >/{{ branch.brCode }}
                      </span>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        dark
                        color="red darken-1"
                        text
                        @click="closeAcceptCase"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="acceptCaseConfirm"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog> -->
                <v-divider
                  v-if="addcase"
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      fab
                      small
                      color="success"
                      dark
                      v-on="{ ...tooltip }"
                      to="/archive"
                    >
                      <v-icon>mdi-archive-alert</v-icon>
                    </v-btn>
                  </template>
                  <span>ARCHIVE</span>
                </v-tooltip>

                <!-- vue-json-to-csv -->
                <!-- <vue-json-to-csv
                  :csv-title="'Report'"
                  :json-data="filteredCasefiles"
                  :labels="{
                    id: { title: 'No' },
                    insurer: { title: 'Insurer' },
                    vehicleNo: { title: 'Vehicle Number' },
                    id: { title: 'AASB Reference' },
                    claimNo: { title: 'Claim Number' },
                    dateOfLoss: { title: 'Date of Loss' },
                    fileStatus: { title: 'Status' },
                  }"
                  @success="(val) => handleSuccess(val)"
                  @error="(val) => handleError(val)"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn fab small color="success" v-on="{ ...tooltip }">
                        <v-icon aria-hidden="true">mdi-file-excel</v-icon>
                      </v-btn>
                    </template>
                    <span>EXPORT XLS</span>
                  </v-tooltip>
                </vue-json-to-csv>
                <v-divider
                  v-if="addcase"
                  class="mx-4"
                  inset
                  vertical
                ></v-divider> -->
                <v-spacer></v-spacer>
                <!-- Search Field -->
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-toolbar>
            </template>

            <template slot="item.numbering" slot-scope="props">
              {{ props.index + 1 }}
            </template>
            <template v-slot:[`item.insurer`]="{ item }">
              <div v-for="ins in filterIns(item.insurer)" :key="ins.id">
                <div class="text-truncate" style="max-width: 100px">
                  {{ ins.name }}
                </div>
              </div>
            </template>
            <template v-slot:[`item.vehicleNo`]="{ item }">
              <span v-if="item.vehicleNo">
                <div v-if="item.vehicleNo">
                  {{ item.vehicleNo.toUpperCase() }}
                </div>
              </span>
              <span v-else>TBA</span>
            </template>
            <template v-slot:[`item.id`]="{ item }">
              <tr class="d-flex">
                AA
                <div
                  v-for="(dept, index) in filterDept(item.refType)"
                  :key="index"
                >
                  /{{ dept.name
                  }}<span
                    v-for="(subDept, index) in filterSubDept(item.subRefType)"
                    :key="index"
                    >/{{ subDept.subCode }}</span
                  >
                </div>

                /{{
                  item.id
                }}/{{
                  yearOnly(item.dateOfAssign)
                }}
                <div
                  v-for="branch in filterBranches(item.branch)"
                  :key="branch.id"
                >
                  /{{ branch.brCode }}
                </div>
              </tr>
            </template>

            <template v-slot:[`item.dateOfAssign`]="{ item }">
              {{ formatDate(item.dateOfAssign) }}
            </template>
            <template v-slot:[`item.days`]="{ item }">
              {{
                dateDiff(moment(item.dateOfAssign).format(), moment().format())
              }}
            </template>

            <!-- Disabled the TAT Counting on 220824 -->

            <!-- <template v-slot:[`item.tatDays`]="{ item }">
              <div class="red--text font-weight-bold">
                {{
                  tatDiff(
                    moment(item.dateOfAssign).format(),
                    moment().format(),
                    filterTatMax(item.insurer, item.subRefType),
                    filterStages(item.fileStatus)
                  )
                }}
              </div>
            </template> -->

            <!-- <template v-slot:[`item.days`]="{ item }">
              {{
                dateDiff(moment(item.dateOfAssign).format(), moment().format())
              }}
              Day(s)
            </template> -->

            <template v-slot:[`item.adjuster`]="{ item }">
              <span v-if="item.adjuster">
                <div
                  v-for="adjuster in filterUser(item.adjuster)"
                  :key="adjuster.id"
                >
                  {{ adjuster.username }}
                </div>
              </span>
              <span v-else>TBA</span>
            </template>
            <template v-slot:[`item.fileStatus`]="{ item }">
              <v-tooltip left transition="fab-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.flag === 1"
                    small
                    color="green lighten-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <!-- mdi-check-decagram-outline -->
                    mdi-shield-check
                  </v-icon>
                  <v-icon
                    v-if="item.flag === 2"
                    small
                    color="orange lighten-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <!-- mdi-alarm-light-off-outline -->
                    mdi-shield-half-full
                  </v-icon>
                  <v-icon
                    v-if="item.flag === 3"
                    small
                    color="red darken-5"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <!-- mdi-alarm-light-off-outline -->
                    mdi-shield-alert-outline
                  </v-icon>
                </template>
                <!-- <span> {{ item.flag ? "Genuine" : "Suspected Fraud" }}</span> -->
                <span v-if="item.flag === 1">GENUINE</span>
                <span v-if="item.flag === 2">SUSPECTED FRAUD</span>
                <span v-if="item.flag === 3">FRAUD</span>
              </v-tooltip>

              <span
                v-for="allStatus in filterStages(item.fileStatus)"
                :key="allStatus.id"
                v-bind="allStatus.item"
              >
                <v-chip label outlined block x-small>
                  {{ allStatus.name }}
                </v-chip>
              </span>

              <!-- <v-tooltip top transition="fab-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    @click="acceptCase(item)"
                    v-if="
                      item.fileStatus == `ADAC` &&
                      item.adjuster == currentUser.id
                    "
                  >
                    mdi-lock-check-outline
                  </v-icon>
                </template>
                <span> Accept Job</span>
              </v-tooltip> -->
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <!-- <v-tooltip left transition="fab-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    @click="viewItem(item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span> View</span>
              </v-tooltip> -->
              <span v-if="management">
                <v-tooltip left transition="fab-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2"
                      @click.stop="updateItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span> edit</span>
                </v-tooltip>
                <v-tooltip right transition="fab-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      color="red darken-1"
                      @click.stop="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span> delete</span>
                </v-tooltip>
              </span>
              <span v-else>
                <v-icon small class="mr-2" disabled> mdi-pencil </v-icon>
                <v-icon small class="mr-2" disabled> mdi-delete </v-icon>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import BranchDataService from "../services/BranchDataService";
import StateDataService from "../services/StateDataService";
import RegionDataService from "../services/RegionDataService";
import DeptDataService from "../services/DeptDataService";
import SubRefTypeDataService from "../services/SubDeptDataService";
import InsDataService from "../services/InsDataService";
import CasefileDataService from "../services/CasefileDataService";
import StagesDataService from "../services/StageDataService";
import HandlerDataService from "../services/HandlerDataService";
import TatChartDataService from "../services/TatChartDataService";
import MailDataService from "../services/MailDataService";
import MemberDataService from "../services/MemberDataService";
// import VueJsonToCsv from "vue-json-to-csv";

import moment from "moment";

export default {
  name: "home",
  components: {
    // VueJsonToCsv
  },
  data: () => ({
    message: "",
    addDialog: false,
    editDialog: false,
    dialogDelete: false,
    // dialogAcceptCase: false,
    casefiles: [],
    search: "",
    management: false,
    addcase: false,
    loadTable: true,

    // activePicker: null,
    // date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    date: null,
    menu: false,
    menu1: false,
    menu2: false,
    menu3: false,

    branch: [],
    state: [],
    regions: [],
    refType: [],
    subRefTypes: [],
    stages: [],
    insurer: [],
    members: [],
    handlers: [],
    tatCharts: [],
    newItem: {
      id: null,
      refType: null,
      subRefType: null,
      branch: null,
      insurer: null,
      handler: null,
      claimNo: "",
      dateOfAssign: "",
      vehicleNo: "",
      dateOfLoss: "",
      stateOfLoss: null,
      placeOfLoss: "",
      insuredName: "",
      insComment: "",
      createdBy: null,
      fileStatus: "",
      dateStartInv: "",
    },
    editItem: {
      id: null,
      refType: null,
      subRefType: null,
      branch: null,
      insurer: null,
      handler: null,
      claimNo: "",
      dateOfAssign: "",
      vehicleNo: "",
      dateOfLoss: "",
      stateOfLoss: null,
      placeOfLoss: "",
      insuredName: "",
      insComment: "",
      createdBy: null,
      fileStatus: "",
      dateStartInv: "",
    },
    headers: [
      {
        text: "NO.",
        align: "start",
        sortable: false,
        value: "numbering",
      },
      {
        text: "VEHICLE NO.",
        align: "center",
        value: "vehicleNo",
        sortable: false,
      },
      {
        text: "AA REF",
        align: "start",
        value: "id",
        sortable: false,
      },

      {
        text: "INSURER",
        align: "center",
        value: "insurer",
        sortable: false,
      },
      {
        text: "DOA",
        align: "start",
        sortable: false,
        value: "dateOfAssign",
      },
      {
        text: "DAYS",
        align: "start",
        sortable: false,
        value: "days",
      },
      // {
      //   text: "TAT",
      //   align: "start",
      //   sortable: false,
      //   value: "tatDays",
      // },
      {
        text: "ADJUSTER",
        align: "start",
        sortable: false,
        value: "adjuster",
      },
      // {
      //   text: "CLAIM NO.",
      //   align: "start",
      //   sortable: false,
      //   value: "claimNo",
      // },

      {
        text: "STATUS",
        align: "center",
        value: "fileStatus",
        sortable: false,
      },

      {
        text: "ACTIONS",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
  }),

  computed: {
    ComputedDateOfAssignAdd() {
      return this.formatDateMethod(this.newItem.dateOfAssign);
    },
    ComputedDateOfLossAdd() {
      return this.formatDateMethod(this.newItem.dateOfLoss);
    },
    ComputedDateOfAssignEdit() {
      return this.formatDateMethod(this.editItem.dateOfAssign);
    },
    ComputedDateOfLossEdit() {
      return this.formatDateMethod(this.editItem.dateOfLoss);
    },
    addCasefileDisabled() {
      return !this.addcase;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    filteredCasefiles() {
      if (
        this.$store.state.auth.user.roles.includes("MANAGER") ||
        this.$store.state.auth.user.roles.includes("ADMIN")
      ) {
        return this.casefiles;
      } else if (
        this.$store.state.auth.user.roles.includes("BRANCHCLERK") ||
        this.$store.state.auth.user.roles.includes("BRANCHMANAGER")
      ) {
        return this.casefiles.filter(
          (x) =>
            this.$store.state.auth.user.dept.includes(x.refType) &&
            this.$store.state.auth.user.branches.includes(x.branch)
        );
      } else if (this.$store.state.auth.user.roles.includes("ADJUSTER")) {
        return this.casefiles.filter(
          (x) =>
            this.$store.state.auth.user.dept.includes(x.refType) &&
            this.$store.state.auth.user.branches.includes(x.branch) &&
            this.$store.state.auth.user.id === x.adjuster
        );
      } else if (this.$store.state.auth.user.roles.includes("EDITOR")) {
        return this.casefiles.filter(
          (x) =>
            this.$store.state.auth.user.dept.includes(x.refType) &&
            this.$store.state.auth.user.branches.includes(x.branch) &&
            this.$store.state.auth.user.id === x.editor
        );
      } else return this.casefiles;
    },
    isAddFileButtonDisabled() {
      return !(
        this.newItem.refType &&
        this.newItem.insurer &&
        this.newItem.claimNo &&
        this.newItem.dateOfAssign &&
        this.newItem.dateOfLoss &&
        this.newItem.insComment
      );
    },
    isEditFileButtonDisabled() {
      return !(
        this.editItem.refType &&
        this.editItem.insurer &&
        this.editItem.claimNo &&
        this.editItem.dateOfAssign &&
        this.editItem.dateOfLoss &&
        this.editItem.insComment
      );
    },
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD/MM/YY") : "";
    },
  },

  watch: {
    addDialog(val) {
      val || this.closeAddDialog();
    },
    editDialog(val) {
      val || this.closeEditDialog();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    // acceptCase(val) {
    //   val || this.closeAcceptCase();
    // },
  },

  methods: {
    moment: function (date) {
      return moment(date);
    },
    handleSuccess() {
      alert("Export Success");
    },
    handleError() {
      alert("Error Exporting");
    },

    filterStages(item) {
      return this.stages.filter((stage) => stage.stageCode === item);
    },
    filterRegions(item) {
      return this.regions.filter((region) => region.branchId === item);
    },
    filtersubRefType(item) {
      return this.subRefTypes.filter(
        (subRefType) => subRefType.deptId === item
      );
    },
    filterHandler(item) {
      return this.handlers.filter((handler) => handler.insId === item);
    },

    // filterBranches(item) {
    //   var emptydata = "TBA";
    //   if (item) {
    //     return this.branch.filter((branch) => branch.id === item);
    //   } else {
    //     return emptydata;
    //   }
    // },
    filterBranches(item) {
      // if (!item) return "";
      return this.branch.filter((branch) => branch.id === item) || "Not Found";
    },

    filterBranchesEmail(item) {
      if (!item) return "TBA";
      const filteredbranch = this.branch.filter((branch) => branch.id === item);
      // console.log(filteredbranch[0].name);
      // return filteredbranch[0] ? filteredbranch[0].name : "Not Found";
      return filteredbranch[0].name;
    },

    filterTatMax(insurerId, subRefTypeId) {
      // if (!insurerId) return 0;
      const filteredIns = this.tatCharts.filter(
        (ins) => ins.insId === insurerId && ins.subDeptId === subRefTypeId
      );
      if (filteredIns[0]) {
        let finalTatMax = filteredIns[0];
        return finalTatMax.tatMax;
      }
    },
    filterTatSubDept(item) {
      if (!item) return 0;
      const filteredIns = this.tatCharts.filter((ins) => ins.id === item);
      return filteredIns[0].subDeptId;
    },
    filterVehiclNo(item) {
      if (!item) return "TBA";
      return item.toLowerCase().replace(/\s/g, "");
    },
    // filterBranches(item) {
    //   if (item) {
    //     return this.branch.filter((branch) => branch.id === item);
    //   } else {
    //     return "";
    //   }
    // },
    filterIns(item) {
      if (!item) return "";
      return this.insurer.filter((insurer) => insurer.id === item);
    },
    filterUser(item) {
      return this.members.filter((member) => member.id === item);
    },
    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subDept) => subDept.id === item);
    },
    checkManagement() {
      if (
        this.$store.state.auth.user.roles.includes(`ADMIN`) ||
        this.$store.state.auth.user.roles.includes(`MANAGER`)
      ) {
        this.management = true;
      }
    },
    checkAddCase() {
      if (
        this.$store.state.auth.user.roles.includes(`ADMIN`) ||
        this.$store.state.auth.user.roles.includes(`MANAGER`) ||
        this.$store.state.auth.user.roles.includes(`CLERK`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHMANAGER`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHCLERK`)
      ) {
        this.addcase = true;
      }
    },
    viewItem(id) {
      this.$router.push({
        name: "vuexFile",
        params: { id: id.id },
      });
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    formatDateMethod(date) {
      if (!date) return null;
      return moment(date).format("DD/MM/YYYY");
    },
    yearOnly(value) {
      // return moment(value).format("YYYY");
      return moment(value).format("YY");
    },
    dateDiff(startDateString, endDateString) {
      let start = moment(startDateString);
      let end = moment(endDateString);
      let duration = moment.duration(end.diff(start));
      return Math.round(duration.asDays());
    },
    // 220824 - TAT in the format of "number of days/ tatMax" =  128/40

    // tatDiff(startDateString, endDateString, tatMax, fileStatus) {
    //   let start = moment(startDateString);
    //   let end = moment(endDateString);
    //   let duration = moment.duration(end.diff(start));
    //   let newDiff = Math.round(duration.asDays());
    //   let newFileStatus = fileStatus[0].stageCode;
    //   // return newDiff - tatMax;
    //   // let overdue = newDiff - tatMax;

    //   if (
    //     tatMax !== null &&
    //     newDiff > tatMax &&
    //     newFileStatus !== "CLO" &&
    //     newFileStatus !== "CANC"
    //   ) {
    //     return newDiff + "/" + tatMax;
    //   } else {
    //     return newDiff;
    //   }
    // },

    retrieveCasefiles() {
      CasefileDataService.getAll()
        .then((response) => {
          this.casefiles = response.data.filter(
            (item) => item.fileStatus !== "CLO" && item.fileStatus !== "CANC"
          );

          this.loadTable = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // retrieveCasefiles() {
    //   CasefileDataService.getAll()
    //     .then((response) => {
    //       this.casefiles = response.data;
    //       this.loadTable = false;
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },
    // getDisplayCasefile(casefile) {
    //   return {
    //     id: casefile.id,
    //     name:
    //       casefile.name.length > 30
    //         ? casefile.name.substr(0, 30) + "..."
    //         : casefile.name,
    //     id:
    //       casefile.id.length > 30
    //         ? casefile.id.substr(0, 30) + "..."
    //         : casefile.id,
    //   };
    // },
    refreshList() {
      this.retrieveCasefiles();
    },
    retrieveDept() {
      DeptDataService.getAll()
        .then((response) => {
          this.refType = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveSubRefType() {
      SubRefTypeDataService.getAll()
        .then((response) => {
          this.subRefTypes = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveStages() {
      StagesDataService.getAll()
        .then((response) => {
          this.stages = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveIns() {
      InsDataService.getAll()
        .then((response) => {
          this.insurer = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveMembers() {
      MemberDataService.getAll()
        .then((response) => {
          this.members = response.data.filter((item) =>
            item.roles.some((b) => b.roleCode === "adjuster")
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveBranches() {
      BranchDataService.getAll()
        .then((response) => {
          // this.branch = response.data;
          this.branch = response.data.map(this.getDisplayBranch);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDisplayBranch(branch) {
      return {
        id: branch.id,
        name:
          branch.name.length > 30
            ? branch.name.substr(0, 30) + "..."
            : branch.name,
        brCode:
          branch.brCode.length > 30
            ? branch.brCode.substr(0, 30) + "..."
            : branch.brCode,
      };
    },
    retrieveStates() {
      StateDataService.getAll()
        .then((response) => {
          // this.branch = response.data;
          this.state = response.data.map(this.getDisplayState);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDisplayState(state) {
      return {
        id: state.id,
        name:
          state.name.length > 30
            ? state.name.substr(0, 30) + "..."
            : state.name,
        stCode:
          state.stCode.length > 30
            ? state.stCode.substr(0, 30) + "..."
            : state.stCode,
      };
    },
    retrieveRegions() {
      RegionDataService.getAll()
        .then((response) => {
          this.regions = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveHandlers() {
      HandlerDataService.getAll()
        .then((response) => {
          this.handlers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveTatCharts() {
      TatChartDataService.getAll()
        .then((response) => {
          this.tatCharts = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // acceptCase(item) {
    //   // this.editedIndex = this.casefiles.indexOf(item);
    //   this.editItem = Object.assign({}, item);
    //   this.editItem.fileStatus = `INVE`;
    //   this.dialogAcceptCase = true;
    // },
    updateItem(item) {
      // this.editedIndex = this.casefiles.indexOf(item);
      this.editItem = Object.assign({}, item);
      this.editDialog = true;
    },
    deleteItem(item) {
      // this.editedIndex = this.casefiles.indexOf(item);
      this.editItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeAddDialog() {
      this.addDialog = false;
      this.$nextTick(() => {
        this.newItem = Object.assign({});
        // this.editedIndex = -1;
      });
    },
    closeEditDialog() {
      this.editDialog = false;
    },
    closeDelete() {
      this.dialogDelete = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    // closeAcceptCase() {
    //   this.dialogAcceptCase = false;
    //   // this.$nextTick(() => {
    //   //   this.editedItem = Object.assign({}, this.defaultItem);
    //   //   this.editedIndex = -1;
    //   // });
    // },
    addCasefile() {
      this.message = "";

      var data = {
        // refType: this.filterDept(this.newItem.refType)[0].description,
        refType: this.newItem.refType,
        subRefType: this.newItem.subRefType,
        // year: this.newItem.year,
        branch: this.newItem.branch,
        insurer: this.newItem.insurer,
        handler: this.newItem.handler,
        claimNo: this.newItem.claimNo,
        dateOfAssign: this.newItem.dateOfAssign,
        vehicleNo: this.filterVehiclNo(this.newItem.vehicleNo),
        // vehicleNo: this.newItem.vehicleNo.toLowerCase().replace(/\s/g, ""),
        dateOfLoss: this.newItem.dateOfLoss,
        stateOfLoss: this.newItem.stateOfLoss,
        placeOfLoss: this.newItem.placeOfLoss,
        insuredName: this.newItem.insuredName,
        insComment: this.newItem.insComment,
        createdBy: this.$store.state.auth.user.id,
        flag: 1,
        fileStatus: `NEW`,
        caseFrom: `ORGANIC`,
      };

      var dataEmail = {
        refType: this.filterDept(this.newItem.refType)[0].description,
        subRefType: this.filterSubDept(this.newItem.subRefType)[0].subCodeDesc,
        branch: this.filterBranchesEmail(this.newItem.branch),
        insurer: this.filterIns(this.newItem.insurer)[0].name,
        // subRefType: this.newItem.subRefType,
        // branch: this.newItem.branch,
        // insurer: this.newItem.insurer,
        handler: this.newItem.handler,
        claimNo: this.newItem.claimNo,
        dateOfAssign: this.newItem.dateOfAssign,
        vehicleNo: this.newItem.vehicleNo.toLowerCase().replace(/\s/g, ""),
        dateOfLoss: this.newItem.dateOfLoss,
        stateOfLoss: this.newItem.stateOfLoss,
        placeOfLoss: this.newItem.placeOfLoss,
        insuredName: this.newItem.insuredName,
        insComment: this.newItem.insComment,
        createdBy: this.$store.state.auth.user.id,
        flag: 1,
        fileStatus: `NEW`,
        caseFrom: `ORGANIC`,
      };
      // console.log(dataEmail.vehicleNo);
      CasefileDataService.create(data)
        .then((response) => {
          // this.newItem.id = response.data.id;
          this.closeAddDialog();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "success",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
          this.refreshList();
          this.data = "";
        })

        .then(() => {
          MailDataService.mailData(dataEmail)
            .then((response) => {
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "success",
                text:
                  (response && response.data && response.data.message) ||
                  toString(),
              });
            })
            .catch((e) => {
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "error",
                text: e || toString(),
              });
            });
          this.dataEmail = "";
        })
        .catch((e) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            text:
              (e.response && e.response.data && e.response.data.message) ||
              e.message ||
              toString(),
          });
        });

      this.closeAddDialog();
    },

    editCasefile() {
      var data1 = {
        refType: this.editItem.refType,
        subRefType: this.editItem.subRefType,
        // year: this.editItem.year,
        branch: this.editItem.branch,
        insurer: this.editItem.insurer,
        handler: this.editItem.handler,
        claimNo: this.editItem.claimNo,
        dateOfAssign: this.editItem.dateOfAssign,
        vehicleNo: this.editItem.vehicleNo.toLowerCase().replace(/\s/g, ""),
        dateOfLoss: this.editItem.dateOfLoss,
        stateOfLoss: this.editItem.stateOfLoss,
        placeOfLoss: this.editItem.placeOfLoss,
        insuredName: this.editItem.insuredName,
        insComment: this.editItem.insComment,
        fileStatus: this.editItem.fileStatus,
      };
      // let newVehicleNo = this.editItem.vehicleNo.toLowerCase().replace(/\s/g, '')
      CasefileDataService.update(this.editItem.id, data1)
        .then((response) => {
          this.closeEditDialog();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "warning",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeEditDialog();
    },
    deleteItemConfirm() {
      CasefileDataService.delete(this.editItem.id)
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeDelete();
    },
    // acceptCaseConfirm() {
    //   CasefileDataService.update(
    //     this.editItem.id,
    //     this.editItem,
    //     (this.editItem.fileStatus = `INVE`),
    //     (this.editItem.dateStartInv = moment().format())
    //   )
    //     .then((response) => {
    //       this.$store.dispatch("setSnackbar", {
    //         showing: true,
    //         color: "green",
    //         text:
    //           (response && response.data && response.data.message) ||
    //           toString(),
    //       });

    //       this.refreshList();
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });

    //   this.closeAcceptCase();
    // },
  },
  mounted() {
    this.retrieveBranches();
    this.retrieveStates();
    this.retrieveRegions();
    this.retrieveDept();
    this.retrieveSubRefType();
    this.retrieveMembers();
    this.retrieveStages();
    this.retrieveIns();
    this.retrieveCasefiles();
    this.checkManagement();
    this.checkAddCase();
    this.retrieveHandlers();
    this.retrieveTatCharts();
  },
};
</script>
